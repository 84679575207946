/* eslint-disable no-useless-escape */
import { computed } from 'vue'
import moment from 'moment'
import { mdiImageOutline, mdiFileDocument, mdiVideoOutline } from '@quasar/extras/mdi-v5'
import { BASE_API_SASS } from '@/services/config'
import { ConfigurationBusiness } from '@/models/core-models'
import { RequestGeneric } from '@/models/requests'

const useUtils = () => {
  const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

  const round = (number: number) => Math.round(number)

  const truncateText = (text: string = '', maxLength: number = 60): string => {
    return (text || '').length > maxLength ? `${text.substring(0, maxLength)}...` : text
  }

  const setFileTypeIcon = (value: string): string => {
    const images = ['png', 'jpg', 'jpeg', 'gif']
    const video = ['mp4', 'webp', 'webm', 'mov', 'avi']
  
    if (images.some(format => value.includes(format))){
      return mdiImageOutline
    } else if (video.some(format => value.includes(format))){
      return mdiVideoOutline
    } else {
      return mdiFileDocument
    }
  }

  const parseKiloBytes = (bytes: number) => Math.floor(bytes / 1024)

  const setCdrStatus = (value: number = 0) => {
    switch (value) {
      case 1:
      case 6:
        return 'Abierto'
      case 2: 
        return 'En progreso'
      case 3:
        return 'Cancelado'
      case 4:
        return 'Rechazado'
      case 5:
        return 'Aceptado'
      default:
        return ''
    }
  }

  const setCrdStatusColor = (value: number = 0) => {
    switch (value) {
      case 1:
      case 6:
        return 'grey'
      case 2: 
        return 'warning'
      case 3:
      case 4:
        return 'red'
      case 5:
        return 'green-6'
      default:
        return 'primary'
    }
  }

  const setLastPathName = (value: string) => {
    if(!value) return ''
    return value.substring(value.lastIndexOf('/') + 1, value.lastIndexOf('?Expires'))
  }

  const handleOpenAttachment = (url: string) => {
    window.open(url, '_blank')
  }

  const objectIsEmpty = (obj: object) => obj ? !Object?.keys(obj)?.length : true

  const groupBy = <T>(array: T[], fn: (item: T) => string): Record<string, T[]> => {
    const result: Record<string, T[]> = {}
    array.forEach(item => {
      const key = fn(item)
      if (!result[key]) {
        result[key] = []
      }
      result[key].push(item)
    })
    return result
  }

  const getQueryStringValue = (key: string, route: string) => {
    const routeWithToken = route?.includes('token') ? route : window.location?.search
    return decodeURIComponent(
      routeWithToken.replace(
        new RegExp(
          '^(?:.*[&\\?]' +
            encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
            '(?:\\=([^&]*))?)?.*$',
          'i'
        ),
        '$1'
      )
    )
  }

  const setUserImage = (imageUrl: string) => {
    const url = new URL(imageUrl)
    if(url.host === 'web:9000'){
      return BASE_API_SASS() + url.pathname
    }
    return imageUrl
  }

  const isActionRestricted = (status: number = 0): boolean => {
    return status === 3 || status === 4 || status === 5
  }

  const formatTwoDigitsNumber = (value: number = 0): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }
  
  const toBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })
  }

  const setTimeNotation = computed(() => {
    return (time: string) => {
      return time ? moment(time, 'h:mm:ss').format('HH:mm') : '-'
    }
  })

  const hasEnabledConfig = (companyConfigurationsList: ConfigurationBusiness[], name: string) => {
    try {
      const item = companyConfigurationsList
      .filter((e) => e.name === name)[0]

      const value = item?.value?.toLowerCase()
      const isActive = item?.active
      return ['si', 's', '1', 'verdadero', 'true'].includes(value) && isActive
    } catch {
      return false
    }
  }

  const sortByDate = (a: RequestGeneric, b: RequestGeneric) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()

  return {
    capitalize,
    round,
    truncateText,
    setFileTypeIcon,
    parseKiloBytes,
    setCdrStatus,
    setCrdStatusColor,
    setLastPathName,
    handleOpenAttachment,
    objectIsEmpty,
    groupBy,
    getQueryStringValue,
    setUserImage,
    isActionRestricted,
    formatTwoDigitsNumber,
    toBase64,
    setTimeNotation,
    hasEnabledConfig,
    sortByDate
  }
}

export default useUtils