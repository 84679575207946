import { RequestGeneric, RequestType } from '@/models/requests'
import { 
  editRequestCompensatoryDays,
  postCompensatoryDays,
  compensatoryDaysReturnDate,
  getListRequestsCompensatoryDays,
  getDetailRequestCompensatoryDay,
  deleteRequestCompensatoryDays
} from '@/modules/requests/services/requests.services'

export const useCompensatoryDays = () => {
  const createRequestCompensatoryDays = async (data: object) => {
    return postCompensatoryDays(data)
  }

  const updateRequestCompensatoryDays = async (id: string | number, request: { status?: 'Aprobada' | 'Rechazada', employee_id?: string }) => {
    return editRequestCompensatoryDays(id, request)
  }

  const cancelRequestCompensatoryDays = async (id: string | number) => {
    return deleteRequestCompensatoryDays(id)
  }

  const getCompensatoryDaysReturnDate = async (queryParams: object) => {
    return compensatoryDaysReturnDate(queryParams)
  }

  const getRequestListCompensatoryDays = async (queryParams: object) => {
    const { data } = await getListRequestsCompensatoryDays(queryParams)
    return data.results.map(data => new RequestGeneric({...data, type: RequestType.CompensatoryDays }))
  }
  const getRequestDetailCompensatoryDays = async (id: string, employeeId?: number) => {
    const { data } = await getDetailRequestCompensatoryDay(id, employeeId)
    return new RequestGeneric({ ...data, type: RequestType.CompensatoryDays }) 
  }
  
  return {
    createRequestCompensatoryDays,
    updateRequestCompensatoryDays,
    getCompensatoryDaysReturnDate,
    getRequestListCompensatoryDays,
    getRequestDetailCompensatoryDays,
    cancelRequestCompensatoryDays
  }
}

export default useCompensatoryDays