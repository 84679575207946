import Vue from 'vue'
import VueRouter from 'vue-router'
import { PiniaVuePlugin } from 'pinia'
import useProfile from '@/modules/profile/composables/useProfile'
import useUtils from '@/modules/shared/composables/useUtils'
import useCheckAndRefreshToken from '@/modules/shared/composables/useCheckRefreshToken'
import routes from './routes'

const { getQueryStringValue } = useUtils()

Vue.use(PiniaVuePlugin)
Vue.use(VueRouter)

const Router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    const position = savedPosition || { x: 0, y: 0 }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position)
      }, 500)
    })
  },
  routes,

  // Leave these as is and change from quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE
})

const allowedEmbedViews = ['Performance', 'performanceIndex', 'objectivesEvaluations', 'competenciesEvaluations', 'objectiveEvaluation', 'competenceEvaluation', 'objectiveFeedback', 'competenceFeedback', 'myObjectivesFeedback', 'myCompetencesFeedback', 'OrganizationalChart']
const embed = JSON.parse(localStorage.getItem('embed') || 'false')

Router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  let loggedIn = localStorage.getItem('user')
  const pendingTerms = JSON.parse(localStorage.getItem('pendingTerms') || 'false') && !JSON.parse(localStorage.getItem('termsSkipped') || 'false')

  const { isBoss } = useProfile()
  // trying to access a restricted page + not logged in
  // redirect to login page

  const jwt = getQueryStringValue('token', window.location.hash)

  // Login when Mi Talana is embedded in Talana Next
  const token = to.query.apitoken || ''
  const apikey = to.query.apikey || ''
  const apitenant: string = to.query?.apitenant as string || ''
  const tenant: string = to.query?.tenant as string || ''

  if(loggedIn && jwt.length){
    loggedIn = null
  }

  if (process.env.NODE_ENV !== 'development') {
    await useCheckAndRefreshToken()
  }

  if(authRequired && !loggedIn){
    if(token.length && apikey.length){
      localStorage.setItem('user',
        JSON.stringify({
          talana_token: token,
          kong_api_key: apikey
        })
      )
      if(apitenant?.length){
        localStorage.setItem('API_URL', apitenant)
      }
      if(tenant?.length){
        localStorage.setItem('API_SASS', tenant)
      }
      next()
    } else {
      next({
        path: '/login',
        query: { 
          token: jwt,
          nextUrl: to.name
        }
      })
    }
  } else if(pendingTerms && !['TermsAndCons', 'Login'].includes(to?.name || '')){
    next({ 
      name: 'TermsAndCons',
      query: {
        nextUrl: to?.name || 'Home'
      }
    })
  } else if(!pendingTerms && ['TermsAndCons'].includes(to?.name || '')){
    next({ name: from?.name || 'Home'})
  } else if(embed && !allowedEmbedViews.includes(to?.name || '')){
    next({ name: 'Performance'})
  } else{
    if (to.matched.some(record => record.meta.boss)) {
      if (isBoss) {
        next()
      } else {
        next({ name: to.meta?.redirect })
      }
    } else {
      next()
    }
  }
})

const DEFAULT_TITLE = 'Portal del Trabajador - Talana'
Router.afterEach((to) => {
  document.title = `${to.meta.title || DEFAULT_TITLE} - Talana`
})


export default Router