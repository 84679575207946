const routes = [
  {
    path: '/change-work-shift',
    name: 'RequestChangeWorkShift',
    component: () => import(/* webpackChunkName: "request-change-work-shift" */ '@/modules/requests/views/ChangeWorkShiftView.vue'),
  },
  {
    path: '/change-work-shift/:id',
    name: 'RequestChangeWorkShiftDetails',
    component: () => import(/* webpackChunkName: "request-change-work-shift-details" */ '@/modules/requests/views/ChangeWorkShiftDetailView.vue'),
  }
]

export default routes