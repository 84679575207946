import { storeToRefs } from 'pinia'

import { useProfileStore } from '@/modules/profile/stores/profile.store'

const useProfile = () => {
  const { getProfile, getShifts, getBrandConfigurations, isBoss, getPermission, companyCountry, getUserStats } = useProfileStore()
  const { stats, status, profile, shifts, brand } = storeToRefs(useProfileStore())

  return {
    status,
    profile,
    shifts,
    brand,
    getProfile,
    getShifts,
    getBrandConfigurations,
    isBoss,
    getPermission,
    companyCountry,
    getUserStats,
    stats,
  }
}

export default useProfile