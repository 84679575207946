import '@/css/tailwind.css'
import Vue, { provide } from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { setDefaultOptions } from 'date-fns'
import { es } from 'date-fns/locale/es'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { ApolloClients } from '@vue/apollo-composable'
import { VueQueryPlugin } from '@tanstack/vue-query'
import './plugins/quasar'
import './plugins/firebase'
import i18n from './boot/i18n'
import './boot/moment'
import './boot/notify-defaults'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  apolloClientHodor
} from '@/modules/shared/api/apolloClient'
import './sw-removal'

Vue.use(PiniaVuePlugin)
export const pinia = createPinia()
pinia.use(createPersistedState({
  storage: sessionStorage
}))

Vue.use(VueQueryPlugin)

setDefaultOptions({
  locale: es
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  pinia,
  i18n,
  setup() {
    provide(ApolloClients, {
      hodor: apolloClientHodor
    })
  },
  render: h => h(App)
}).$mount('#app')
