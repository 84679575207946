import axios from 'axios'
import { EMPLOYEE } from '@/services/urls'
import authHeader from '@/services/auth-header'

export const getListRequestsCompensatoryDays = async (params: object) => {
  const url = `${EMPLOYEE()}/compensation-request-list-pdt/`
  const options = {
    method: 'GET',
    withCredentials: true,
    headers: authHeader(),
    params,
    url,
  }
  return axios(options)
}

export const deleteRequestCompensatoryDays = async (id: string | number) => {
  const url = `${EMPLOYEE()}/compensation-request/pdt/${id}`
  const options = {
    method: 'DELETE',
    withCredentials: true,
    headers: authHeader(),
    url,
  }
  return axios(options)
}

export const getDetailRequestCompensatoryDay = async (id: string, employeeId?: number) => {
  const url = `${EMPLOYEE()}/compensation-request/pdt/${id}`
  const params = employeeId ? {employee_id: employeeId} : {}
  const options = {
    method: 'GET',
    withCredentials: true,
    headers: authHeader(),
    params,
    url,
  }
  return axios(options)
}

export const editRequestCompensatoryDays = async (id: string | number, data: { status?: 'Aprobada' | 'Rechazada', employee_id?: string }) => {
  const url = `${EMPLOYEE()}/compensation-request/pdt/${id}`
  const options = {
    method: 'PATCH',
    withCredentials: true,
    headers: authHeader(),
    data: data,
    url,
  }
  return axios(options)
}

export const postCompensatoryDays = async (data) => {
  const url = `${EMPLOYEE()}/compensation-request/pdt/`
  const options = {
    method: 'POST',
    withCredentials: true,
    headers: authHeader(),
    data: data,
    url,
  }
  return axios(options)
}

export const compensatoryDaysReturnDate = (queryParams = {}) => {
  return axios.get(`${EMPLOYEE()}/compensation-request/return_date`, { withCredentials: true, headers: authHeader(), params: queryParams })
}