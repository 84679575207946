import { RouteConfig } from 'vue-router'
import organizationalChartRoutes from '@/modules/organizational-chart/router'
import contactRoutes from '@/modules/contact/router'
import requestsRoutes from '@/modules/requests/router'

const routes: RouteConfig[] = [
  { 
    name: 'Login',
    path: '/login',
    component: () => import('@/layouts/PublicLayout.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/',
    meta: {
      Auth: true,
      title: 'Inicio'
    },
    redirect: { name: 'Home' },
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      { 
        name: 'Home',
        path: 'home',
        component: () => import('@/pages/Index.vue'),
        meta: {
          title: 'Inicio'
        }
      },
      { 
        name: 'MyTeam',
        path: 'my-team',
        component: () => import('@/modules/myTeam/pages/MyTeamPage.vue'),
        meta: {
          title: 'Mi equipo'
        }
      },
      {
        name: 'Documents',
        path: 'documents',
        component: () => import('@/pages/Documents.vue'),
        redirect: {
          name: 'Folders'
        },
        meta: {
          title: 'Documentos'
        },
        children: [
          { 
            name: 'Folders',
            path: 'folders',
            component: () => import('@/pages/documents/DocumentsFolders.vue'),
            meta: {
              title: 'Documentos empresa'
            },
          },
          { 
            name: 'MyFolders',
            path: 'myFolders',
            component: () => import('@/pages/documents/MyDocumentsFolders.vue'),
            meta: {
              title: 'Mis documentos'
            },
          },
          { 
            name: 'DocumentsSignList',
            path: 'documentsSign',
            component: () => import('@/pages/documentsSign/DocumentsSignList.vue'),
            meta: {
              title: 'Documentos por firmar'
            },
          },
          { 
            name: 'FolderList',
            path: 'folderList',
            component: () => import('@/pages/documents/DocumentsFolderList.vue'),
            props: true,
            meta: {
              title: 'Listado de documentos'
            },
          },
          { 
            name: 'AttendanceFolderList',
            path: 'attendanceFolderList',
            component: () => import('@/pages/documents/AttendanceFolderList.vue'),
            props: true,
            meta: {
              title: 'Documentos de asistencia'
            }
          },
        ]
      },
      { name: 'RequestsView',
        path: 'requests',
        component: () => import('@/modules/requests/views/RequestsView.vue'),
        redirect: {
          name: 'All Requests'
        },
        meta: {
          title: 'Solicitudes'
        },
        children: [
          {
            name: 'All Requests',
            path: '',
            component: () => import('@/pages/requests/AllRequestsWrapper.vue'),
          },
          { 
            name: 'Requests Selector',
            path: 'selector',
            redirect: {
              name: 'All Requests',
            },
          },
          { 
            name: 'My Request',
            path: 'me',
            redirect: {
              name: 'All Requests',
            },
          },
          {
            name: 'Team Requests',
            path: 'team',
            redirect: {
              name: 'All Requests',
            }
          }
        ]
      },
      {
        name: 'RequestDetail',
        path: 'requestDetail/:id',
        component: () => import('@/pages/requests/RequestDetail.vue'),
        props: true,
      },
      {
        name: 'RequestVacations',
        path: 'requestsVacations',
        component: () => import('@/pages/requests/RequestVacations.vue'),
        props: true,
        meta: {
          title: 'Solicitar vacaciones'
        }
      },
      {
        name: 'RequestPermissions',
        path: 'requestsPermissions',
        component: () => import('@/pages/requests/RequestPermissions.vue'),
        props: true,
        meta: {
          title: 'Solicitar permiso'
        }
      },
      {
        name: 'RequestAdministrative',
        path: 'requestsAdministrative',
        component: () => import('@/pages/requests/RequestAdministrative.vue'),
        props: true,
        meta: {
          title: 'Solicitar días administrativos'
        }
      },
      { 
        name: 'RequestExtraHours',
        path: 'requestsExtraHours',
        component: () => import('@/pages/requests/RequestExtraHours.vue'),
        props: true,
        meta: {
          title: 'Solicitar horas extras'
        }
      },
      {
        name: 'RequestCompensatoryDays',
        path: 'requestCompensatoryDays',
        component: () => import('@/pages/requests/RequestCompensatoryDays.vue'),
        props: true,
        meta: {
          title: 'Solicitar días compensatorios'
        }
      },
      ...contactRoutes,
      ...requestsRoutes,
      { 
        name: 'Birthday',
        path: 'cumpleanos',
        component: () => import('@/pages/birthdays/Birthday.vue'),
        meta: {
          title: 'Cumpleaños'
        },
      },
      { 
        name: 'BirthdaySearch',
        path: 'cumpleanos/busqueda',
        component: () => import('@/pages/birthdays/BirthdaySearch.vue'),
        props: true,
        meta: {
          title: 'Búsqueda cumpleaños'
        },
      },
      { 
        name: 'MyTeamBirthdays',
        path: 'cumpleanos/mi-equipo',
        component: () => import('@/pages/birthdays/MyTeamBirthdays.vue'),
        meta: {
          title: 'Cumpleaños mi equipo'
        },
      },
      {
        name: 'Marks',
        path: 'marks',
        component: () => import('@/pages/Marks.vue'),
        meta: {
          title: 'Marcas'
        }
      },
      {
        name: 'HelpDesk',
        path: 'help-desk',
        redirect: { name: 'Selection' },
        meta: {
          title: 'Centro de Requerimientos'
        },
        component: () => import('@/pages/HelpDesk.vue'),
        children: [
          { 
            name: 'Selection',
            path: '',
            component: () => import('@/pages/helpdesk/HelpDeskSelection.vue'),
            meta: {
              title: 'Centro de Requerimientos'
            },
          },
          { 
            name: 'MyInbox',
            path: 'inbox',
            component: () => import('@/pages/helpdesk/HelpDeskInbox.vue'),
            meta: {
              title: 'Responder requerimientos'
            },
          },
          {
            name: 'MyTickets',
            path: 'my-tickets',
            component: () => import('@/pages/helpdesk/MyTickets.vue'),
            meta: {
              title: 'Mis requerimientos'
            }
          },
          { 
            name: 'TicketDetail',
            path: 'ticket/:id/',
            component: () => import('@/pages/helpdesk/TicketDetail.vue'),
            meta: {
              title: 'Detalle requerimiento'
            }
          },
          { 
            name: 'TicketAnswer',
            path: 'ticket-answer/:id/',
            component: () => import('@/pages/helpdesk/TicketAnswer.vue'),
            meta: {
              title: 'Responder requerimiento'
            }
          },
          { 
            name: 'AddTicket',
            path: 'add',
            component: () => import('@/pages/helpdesk/TicketAdd.vue'),
            meta: {
              title: 'Nuevo requerimiento'
            }
          },
          { 
            name: 'SearchTicket',
            path: 'ticket-search',
            component: () => import('@/pages/helpdesk/SearchTicket.vue'),
            props: true,
            meta: {
              title: 'Buscar requerimiento'
            }
          },
          { 
            name: 'QueueManager',
            path: 'ticket-administration',
            component: () => import('@/pages/helpdesk/QueueAdministration.vue'),
            meta: {
              title: 'Supervisar requerimientos'
            },
          },
          { 
            name: 'TicketManagerDetail',
            path: 'ticket-detail/:id/',
            component: () => import('@/pages/helpdesk/TicketManagerDetail.vue'),
            meta: {
              title: 'Supervisar requerimiento',
            },
          }
        ]
      },
      {
        name: 'Performance',
        path: 'performance',
        component: () => import('@/pages/Performance.vue'),
        meta: {
          title: 'Desempeño'
        },
        redirect: {
          name: 'objectivesEvaluations'
        },
        children: [
          { 
            name: 'performanceIndex',
            path: '',
            redirect: {
              name: 'objectivesEvaluations'
            },
            component: () => import('@/pages/performance/PerformanceIndex.vue'),
            children: [
              { 
                name: 'objectivesEvaluations',
                path: 'objectives',
                component: () => import('@/components/performance/Performance.vue'),
                meta: {
                  title: 'Objetivos',
                },
              },
              { 
                name: 'competenciesEvaluations',
                path: 'competencies',
                component: () => import('@/components/performance/Competencies.vue'),
                meta: {
                  title: 'Competencias',
                },
              }
            ]
          },
          { 
            name: 'objectiveEvaluation',
            path: 'objectives/:id',
            component: () => import('@/pages/performance/PerformanceDetail.vue'),
            meta: {
              title: 'Evaluación objetivos',
            },
          },
          { 
            name: 'competenceEvaluation',
            path: 'competencies/:id',
            component: () => import('@/pages/performance/PerformanceDetail.vue'),
            meta: {
              title: 'Evaluación competencias',
            },
          },
          { 
            name: 'objectiveFeedback',
            path: 'feedback-objectives/:id',
            component: () => import('@/pages/performance/FeedbackDetail.vue'),
            meta: {
              title: 'Feedback objetivos',
            },
          },
          { 
            name: 'competenceFeedback',
            path: 'feedback-competencies/:id',
            component: () => import('@/pages/performance/FeedbackDetail.vue'),
            meta: {
              title: 'Feedback competencias',
            },
          },
          { 
            name: 'myObjectivesFeedback',
            path: 'my-objectives-feedback-review/:id',
            component: () => import('@/pages/performance/MyFeedbackReview.vue'),
            meta: {
              title: 'Mi feedback objetivos',
            },
          },
          { 
            name: 'myCompetencesFeedback',
            path: 'my-competencies-feedback-review/:id',
            component: () => import('@/pages/performance/MyFeedbackReview.vue'),
            meta: {
              title: 'Mi feedback competencias',
            },
          },
        ]
      },
      { 
        name: 'PostsByCategory',
        path: '/category/:id',
        component: () => import('@/pages/posts/PostsByCategory.vue'),
        props: true,
        meta: {
          title: 'Categorías'
        },
      },
      {
        name: 'Benefits',
        path: '/beneficios',
        component: () => import('@/pages/benefits/Benefits.vue'),
        meta: {
          title: 'Beneficios'
        },
      },
      {
        name: 'favoriteBenefits',
        path: '/beneficios/favoritos',
        component: () => import('@/pages/benefits/FavoriteBenefits.vue'),
        meta: {
          title: 'Beneficios favoritos'
        },
      },
      {
        name: 'Tutorials',
        path: '/tutoriales',
        component: () => import('@/pages/tutorials/TutorialsList.vue'),
        meta: {
          title: 'Tutoriales'
        }
      },
      {
        name: 'TutorialDetail',
        path: '/tutorial/:id',
        props: true,
        component: () => import('@/pages/tutorials/TutorialDetail.vue'),
        meta: {
          title: 'Tutorial'
        }
      },
      {
        name: 'Events',
        path: '/eventos',
        component: () => import('@/pages/events/Events.vue'),
        meta: {
          title: 'Eventos'
        },
      },
      {
        name: 'EditedWorkshifts',
        path: '/jornadas-editadas/:type?',
        component: () => import('@/pages/workshifts/WorkshiftsList.vue'),
        meta: {
          title: 'Jornadas editadas'
        },
      },
      {
        name: 'WorkshiftDetail',
        path: '/jornadas-editadas/:type/:id',
        component: () => import('@/pages/workshifts/WorkshiftDetail.vue'),
        meta: {
          title: 'Jornada editada'
        },
      },
      {
        name: 'Groups',
        path: '/comunidades',
        component: () => import('@/pages/groups/GroupLists.vue'),
        props: true,
        meta: {
          title: 'Comunidades'
        },
      },
      ...organizationalChartRoutes,
    ]
  },
  {
    path: '/beneficios/tarjeta',
    name: 'BenefitCard',
    meta: {
      Auth: true,
      title: 'Tarjeta Club Talana'
    },
    component: () => import('@/pages/benefits/BenefitCard.vue'),
  },
  {
    path: '/terminos-condiciones',
    name: 'TermsAndCons',
    meta: {
      Auth: true,
      title: 'Términos y condiciones'
    },
    component: () => import('@/layouts/TermsConditionsLayout.vue')
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('@/layouts/MainLayout.vue'),
    redirect: { name: '404' },
    children: [
      {
        name: '404',
        path: 'error',
        component: () => import('@/pages/Error404.vue'),
        meta: {
          title: 'Página no encontrada'
        },
      }
    ]
  }
]

export default routes
